/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import "./DataTableHeader.css";
import { ReactComponent as SearchIcon } from "./../../assets/svgs/search-bar.svg";
import { ReactComponent as SearchBarClick } from "./../../assets/svgs/search-bar-click.svg";
import { ReactComponent as Instructions } from "./../../assets/svgs/instructions.svg";
import DropDownComponent from "../DropDownComponent/DropDownComponent";
import { useSelector } from "react-redux";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { jwtDecode } from "jwt-decode";
import Button from "../Button/Button";
import PopupWizard from "../PopupWizard/PopupWizard";
import { useLocation } from "react-router-dom";
import {
  setFiltersListPage,
  fetchCompanies,
  setSearchQueryDetailsPage,
} from "../../redux/companies/actionCreator";
import { useDispatch } from "react-redux";
import RenderIf from "../RenderIf/RenderIf";

const DataTableHeader = ({
  handleFilterChange,
  clearFilters,
  clickedClearFilters,
  setClickedClearFilters,
  setPage,
}) => {
  const { agencies } = useSelector((state) => state.Agencies);
  const { filters, searchQuery } = useSelector((state) => state.Companies);

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const checkAdminStatus = () => {
    const token = localStorage.getItem("token");
    const user = jwtDecode(token); // Decode token to get user details
    if (user?.role == "admin") return true;
    return false;
  };

  // Check local storage to decide whether to show the modal automatically
  useEffect(() => {
    const modalShown = localStorage.getItem("modalShown");
    if (!modalShown && !checkAdminStatus()) {
      setIsOpen(true);
      localStorage.setItem("modalShown", "true");
    }
  }, []);

  const getDropDownComponentsData = () => {
    const dropDownComponentsData = [
      {
        options: ["All Customer Types", "Agency", "Sub-Account"],
        placeholder: "Customer Type",
        filtering: "customer_type",
        value: filters?.customer_type,
      },
      {
        options: ["All Statuses", "Paid", "Unpaid", "Cancelled"],
        placeholder: "Status",
        filtering: "payment_status",
        value: filters?.payment_status,
      },
      {
        options: ["All Agencies", ...agencies],
        placeholder: "Sub-Accounts",
        filtering: "agency",
        value: filters?.agency,
      },
    ];
    if (checkAdminStatus()) return dropDownComponentsData;
    return dropDownComponentsData.filter((_, index) => index === 1);
  };

  const buttonClicked = () => {
    setClickedClearFilters(!clickedClearFilters);
    dispatch(
      setFiltersListPage({
        customer_type: "",
        name: "",
        payment_status: "",
        agency: "",
        start: "",
        end: "",
      })
    );
    setPage(0);
  };

  let searchTimeout;

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      const searchQuery = e.target.value;
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      dispatch(setSearchQueryDetailsPage(searchQuery));
      searchTimeout = setTimeout(() => {
        dispatch(fetchCompanies(searchQuery));
      }, 1000);
      setPage(0);
    },
    [dispatch, setPage]
  );

  const searchValue = searchQuery || "";

  const location = useLocation(); // Get current location
  useEffect(()=>{
    dispatch(setSearchQueryDetailsPage(searchValue));
    dispatch(fetchCompanies(searchValue))
    setPage(0);
  },
  [location])

  return (
    <div className="px-6 py-3 flex flex-col justify-between">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="text-base leading-6 font-semibold dark:text-white text-[#32394E]">
            COMPANIES
          </div>
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => setIsOpen(true)}
          >
            <Instructions />
            <div className="font-semibold text-[#556EE6] text-[13px] ml-[6px]">
              Instructions
            </div>
          </div>
        </div>

        <PopupWizard isOpen={isOpen} setIsOpen={setIsOpen} />
        {/* Here container starts */}
        <div className="flex flex-col xl:flex-row items-center lg:justify-between dark:bg-[#32384B] bg-white p-4 w-full rounded-md justify-center">
          <div className="rounded-2xl flex items-center dark:bg-[#2E3548] bg-white p-[9px] dark:border-none border-[1px] border-solid border-[#CED4DA]">
            <span>
              <SearchIcon className="me-2" />
            </span>
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
              value={searchValue}
              className="ghl-search-input py-1 px-0 font-normal dark:bg-[#2E3548] bg-white "
            />
            <span>
              <SearchBarClick className="me-2 " />
            </span>
          </div>

          <div className="flex flex-col md:flex-row gap-1 bg-yellow-30 m-1 text-base leading-6 font-semibold text-white w-full sm:justify-center xl:justify-end justify-center">
            <Button
              backgroundColor="#556EE6"
              textColor="white"
              hoverTextColor="#647adb"
              textContent="Clear Filters"
              darkModeStyle=""
              togglePopup={buttonClicked}
            />
            <RenderIf isTrue={checkAdminStatus()}>
              <DateRangePicker
                filtering="date_added"
                handleFilterChange={handleFilterChange}
                clearFilters={clearFilters}
                clickedClearFilters={clickedClearFilters}
                setClickedClearFilters={setClickedClearFilters}
                buttonClicked={buttonClicked}
                setPage={setPage}
              />
            </RenderIf>
            {getDropDownComponentsData().map((item, index) => (
              <DropDownComponent
                clickedClearFilters={clickedClearFilters}
                key={index}
                options={item.options}
                filtering={item.filtering}
                handleFilterChange={handleFilterChange}
                placeholder={item.placeholder}
                clearFilters={clearFilters}
                setPage={setPage}
                value={item.value}
                filters={filters}
              />
            ))}
          </div>
        </div>
        {/* Here container ends */}
      </div>
    </div>
  );
};

export default DataTableHeader;
