import actions from "./actions";
import api from "../../utils/apiData";
import { isSuccess } from "../../utils/commonMethods";

const {
  fetchCompaniesBegin,
  fetchCompaniesSuccess,
  fetchCompanyBegin,
  fetchCompanySuccess,
  fetchSubAccountBegin,
  fetchAgencyEmailBegin,
  fetchAgencyEmailSuccess,
  fetchSubAccountSuccess,
  updateCompanyBegin,
  updateCompanySuccess,
  deleteCompanyBegin,
  deleteCompanySuccess,
  deleteSubAccountBegin,
  deleteSubAccountSuccess,
  setSearchQuery,
  setFilters,
  clearFilters,
  apiError,
  clearMessageError,
} = actions;

export const fetchCompanies = (params) => async (dispatch) => {
  try {
    dispatch(fetchCompaniesBegin());
    const response = await (params
      ? api.companies.get(params)
      : api.companies.getAll());
    if (isSuccess(response))
      dispatch(fetchCompaniesSuccess(response.data.companies));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const fetchCompany = (id) => async (dispatch) => {
  try {
    dispatch(fetchCompanyBegin());
    const response = await api.companies.getCompany(id);
    if (isSuccess(response))
      dispatch(fetchCompanySuccess(response.data.company));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const fetchAgencyEmail = (id) => async (dispatch) => {
  try {
    dispatch(fetchAgencyEmailBegin());
    const response = await api.companies.fetchAgencyEmailOfSubAccount(id);
    if (isSuccess(response))
      dispatch(fetchAgencyEmailSuccess(response.data.company));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const deleteCompany = (id) => async (dispatch) => {
  try {
    dispatch(deleteCompanyBegin());
    const response = await api.companies.deleteCompany(id);
    if (isSuccess(response)) dispatch(deleteCompanySuccess(response.data));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const deleteSubAccount = (location_id) => async (dispatch) => {
  try {
    dispatch(deleteSubAccountBegin());
    const response = await api.companies.deleteSubAccount(location_id);
    if (isSuccess(response)) dispatch(deleteSubAccountSuccess(response.data));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const fetchSubAccount = (id) => async (dispatch) => {
  try {
    dispatch(fetchSubAccountBegin());
    const response = await api.companies.getSubAccount(id);
    if (isSuccess(response))
      dispatch(fetchSubAccountSuccess(response.data.company));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const updateCompany = (id, value, key) => async (dispatch) => {
  try {
    dispatch(updateCompanyBegin());
    const response = await api.companies.updateCompany(id, value, key);
    if (isSuccess(response))
      dispatch(updateCompanySuccess(response.data.company));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const setSearchQueryDetailsPage = (data) => async (dispatch) => {
  dispatch(setSearchQuery(data));
};

export const setFiltersListPage = (data) => async (dispatch) => {
  dispatch(setFilters(data));
};

export const clearFiltersListPage = () => async (dispatch) => {
  dispatch(clearFilters());
};

export const clearMessage = () => async (dispatch) => {
  dispatch(clearMessageError());
};
