/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "./../../assets/svgs/back-icon.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/svgs/delete-icon.svg";
import { ReactComponent as DeleteCompanyIcon } from "./../../assets/svgs/delete-strategy-icon.svg";
import { jwtDecode } from "jwt-decode";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import CompanyDetails from "../../components/CompanyDetails/CompanyDetails";
import DeleteCompany from "../../components/DeleteCompany/DeleteCompany";
import { Dialog } from "@mui/material";
import {
  fetchCompany,
  fetchSubAccount,
  deleteSubAccount,
  deleteCompany,
  setFiltersListPage
} from "../../redux/companies/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import RenderIf from "../../components/RenderIf/RenderIf";
import { toast } from "react-toastify";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DetailsPage = () => {
  const [role, setRole] = useState(null);
  const query = useQuery();
  const location_id = query.get("location_id");
  const company_id = query.get("company_id");
  const { currentCompany, success, loading, filters, total } = useSelector(
    (state) => state.Companies
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    location_id
      ? dispatch(fetchSubAccount(location_id))
      : dispatch(fetchCompany(company_id));
  }, []);

  const [isDeleteIconOpen, setIsDeleteIconOpen] = useState(false);
  const toggleDeleteIcon = () => {
    setIsDeleteIconOpen(!isDeleteIconOpen);
  };

  const deleteCurrentCompany = () => {
    if (location_id) {
      dispatch(deleteSubAccount(location_id));
      toast.success("Sub-account deleted successfully!");
    } else {
      dispatch(deleteCompany(company_id));
      toast.success("Company deleted successfully!");
    }
    if (
      filters?.pageSize * filters?.pageIndex == total - 1 &&
      filters?.pageIndex > 0
    ) {
      const newPageIndex = filters?.pageIndex - 1;
      dispatch(setFiltersListPage({ pageIndex: newPageIndex }));
    }
    setIsDeleteIconOpen(!isDeleteIconOpen);
    navigate("/");
  };

  useEffect(() => {
    // Check if the user is already authenticated
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const user = jwtDecode(token); // If token is valid, redirect to the home page
        setRole(user?.role);
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, []);


  return (
    <div className="flex flex-col ">
      <Header />

      <RenderIf
        isTrue={!loading && success}
        fallback={
          <div className="flex items-center justify-center h-screen">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        }
      >
        <div className="flex flex-col min-h-[85vh] bg-[#f1f2f6] dark:bg-[#222737] w-full text-white">
          <div className="flex-grow">
            <div className="text-white flex flex-row justify-between">
              <div className="flex flex-row pl-[24px] pt-[26px]">
                <Link to="/" className="text-[#556EE6]">
                  <div className="cursor-pointer">
                    <span>
                      <BackIcon />
                    </span>
                  </div>
                </Link>

                <div className="text-base font-semibold ml-[8px] text-[#32394E] dark:text-white">
                  COMPANY DETAILS
                </div>
              </div>

              <div
                className={`flex flex-row pr-[24px] pt-[24px] ${
                  !location_id && role !== "admin" && "flex-row-reverse"
                }`}
              >
                <div className="mr-[16px]">
                  <span
                    className={`text-sm px-2 text-[#34C38F] py-1 font-medium rounded-full dark:bg-[#2b3f4a] bg-[#ebf9f4]`}
                  >
                    {currentCompany.payment_status}
                  </span>
                </div>

                <div
                  className={`flex cursor-pointer ${
                    !location_id && role !== "admin" && "invisible"
                  }`}
                  onClick={toggleDeleteIcon}
                >
                  <span title="Delete Company">
                    <DeleteIcon />
                  </span>

                  <span className="text-xs font-medium text-[#F33C3C] mt-1">
                    Delete
                  </span>
                </div>
              </div>
            </div>
            <CompanyDetails
              currentCompany={currentCompany}
              id={location_id ? location_id : company_id}
              status={location_id ? "subAccount" : "company"}
            />
          </div>
          <Dialog
            open={isDeleteIconOpen}
            onClose={() => setIsDeleteIconOpen(false)}
            fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: { borderRadius: "7px", background: "#2A3042" },
            }}
          >
            <DeleteCompany
              icon={<DeleteCompanyIcon />}
              title={"Are you sure you want to delete this company?"}
              content={"This company will be deleted from the system."}
              button={"Delete"}
              buttonColour="var(--tooltip-price-color)"
              togglePopup={toggleDeleteIcon}
              deleteCurrentCompany={deleteCurrentCompany}
            />
          </Dialog>
        </div>
      </RenderIf>
      <Footer />
    </div>
  );
};

export default DetailsPage;
