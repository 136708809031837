/* eslint-disable */
import React, { useEffect } from "react";
import PaginationComponent from "../Pagination/Pagination";
import DropDownComponent from "../DropDownComponent/DropDownComponent";
import { useSelector } from "react-redux";

const DataTableBottom = ({
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageIndex,
  pageCount,
  setPage,
  pageSize,
  setPageSize,
  gotoPage,
  data, // This prop is the current page's data
  totalRecords, // This prop is the total number of filtered records
}) => {
  const { filters } = useSelector((state) => state.Companies);

  useEffect(() => {
    setPageSize(Number(filters?.pageSize) || 5);
  }, []);

  const onChange = (value) => {
    setPageSize(Number(value));
    // setPage(0);
  };

  // Calculate the number of items currently being displayed
  const currentPageSize = data.length;

  // Calculate the range of items currently being displayed
  const fromItem = pageIndex * pageSize + 1;
  const toItem = fromItem + currentPageSize - 1;

  return (
    <div className="flex flex-col md:flex-row items-center justify-between font-normal">
      <div className="m-3 text-xs dark:text-white text-black font-semibold flex flex-col">
        <div>
          <span className="font-bold dark:text-[#C3CBE4] text-[#62697A] items-center">
            Total Records:{" "}
          </span>
          <span>{totalRecords}</span>
        </div>
        <div className="mt-2">
          <span className="font-bold dark:text-[#C3CBE4] text-[#62697A] items-center">
            Showing From{" "}
          </span>
          <span>
            {fromItem} to {toItem}
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-2">
        <div>
          <DropDownComponent
            options={["5", "10", "25"]}
            placeholder={pageSize}
            filtering="pageSize"
            onChange={onChange}
            setPage={setPage}
          />
        </div>
        <PaginationComponent
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPage={gotoPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
};

export default DataTableBottom;
