const actions = {
  FETCH_COMPANIES_BEGIN: "FETCH_COMPANIES_BEGIN",
  FETCH_COMPANIES_SUCCESS: "FETCH_COMPANIES_SUCCESS",
  FETCH_COMPANY_BEGIN: "FETCH_COMPANY_BEGIN",
  FETCH_COMPANY_SUCCESS: "FETCH_COMPANY_SUCCESS",
  FETCH_SUB_ACCOUNT_BEGIN: "FETCH_SUB_ACCOUNT_BEGIN",
  FETCH_SUB_ACCOUNT_SUCCESS: "FETCH_SUB_ACCOUNT_SUCCESS",
  UPDATE_COMPANY_BEGIN: "UPDATE_COMPANY_BEGIN",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
  DELETE_COMPANY_BEGIN: "DELETE_COMPANY_BEGIN",
  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  DELETE_SUB_ACCOUNT_BEGIN: "DELETE_SUB_ACCOUNT_BEGIN",
  DELETE_SUB_ACCOUNT_SUCCESS: "DELETE_SUB_ACCOUNT_SUCCESS",
  FETCH_AGENCY_EMAIL_BEGIN: "FETCH_AGENCY_EMAIL_BEGIN",
  FETCH_AGENCY_EMAIL_SUCCESS: "FETCH_AGENCY_EMAIL_SUCCESS",

  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",

  SET_FILTERS: "SET_FILTERS",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  API_ERROR: "API_ERROR",
  CLEAR_MESSAGE_ERROR: "CLEAR_MESSAGE_ERROR",

  fetchCompaniesBegin: () => ({
    type: actions.FETCH_COMPANIES_BEGIN,
  }),

  fetchCompaniesSuccess: (data) => ({
    type: actions.FETCH_COMPANIES_SUCCESS,
    data,
  }),
  fetchCompanyBegin: () => ({
    type: actions.FETCH_COMPANY_BEGIN,
  }),

  setFilters: (data) => ({
    type: actions.SET_FILTERS,
    data,
  }),
  clearFilters: () => ({
    type: actions.CLEAR_FILTERS,
  }),

  fetchCompanySuccess: (data) => ({
    type: actions.FETCH_COMPANY_SUCCESS,
    data,
  }),

  setSearchQuery: (data) => ({
    type: actions.SET_SEARCH_QUERY,
    data,
  }),

  fetchSubAccountBegin: () => ({
    type: actions.FETCH_SUB_ACCOUNT_BEGIN,
  }),

  fetchSubAccountSuccess: (data) => ({
    type: actions.FETCH_SUB_ACCOUNT_SUCCESS,
    data,
  }),

  updateCompanyBegin: () => ({
    type: actions.UPDATE_COMPANY_BEGIN,
  }),

  updateCompanySuccess: (data) => ({
    type: actions.UPDATE_COMPANY_SUCCESS,
    data,
  }),

  deleteCompanyBegin: () => ({
    type: actions.DELETE_COMPANY_BEGIN,
  }),

  deleteCompanySuccess: (data) => ({
    type: actions.DELETE_COMPANY_SUCCESS,
    data,
  }),

  deleteSubAccountBegin: () => ({
    type: actions.DELETE_SUB_ACCOUNT_BEGIN,
  }),

  deleteSubAccountSuccess: (data) => ({
    type: actions.DELETE_SUB_ACCOUNT_SUCCESS,
    data,
  }),

  fetchAgencyEmailBegin: () => ({
    type: actions.FETCH_AGENCY_EMAIL_BEGIN,
  }),

  fetchAgencyEmailSuccess: (data) => ({
    type: actions.FETCH_AGENCY_EMAIL_SUCCESS,
    data,
  }),

  apiError: (errorMessage) => ({
    type: actions.API_ERROR,
    err: errorMessage,
  }),

  clearMessageError: () => ({
    type: actions.CLEAR_MESSAGE_ERROR,
  }),
};

export default actions;
