// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ghl-search-input {
    min-width: 240px;
    border: none;
    outline: none;
    color: var(--search-input-text-color);
    font-size: var(--base-font-size);
    line-height: 16px;
    letter-spacing: 0.02em;
  }
`, "",{"version":3,"sources":["webpack://./src/components/DataTableHeader/DataTableHeader.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,qCAAqC;IACrC,gCAAgC;IAChC,iBAAiB;IACjB,sBAAsB;EACxB","sourcesContent":[".ghl-search-input {\n    min-width: 240px;\n    border: none;\n    outline: none;\n    color: var(--search-input-text-color);\n    font-size: var(--base-font-size);\n    line-height: 16px;\n    letter-spacing: 0.02em;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
