const actions = {
  FETCH_STATUSES_BEGIN: "FETCH_STATUSES_BEGIN",
  FETCH_STATUSES_SUCCESS: "FETCH_STATUSES_SUCCESS",
  API_ERROR: "API_ERROR",
  CLEAR_MESSAGE_ERROR: "CLEAR_MESSAGE_ERROR",

  fetchStatusesBegin: () => ({
    type: actions.FETCH_STATUSES_BEGIN,
  }),

  fetchStatusesSuccess: (data) => ({
    type: actions.FETCH_STATUSES_SUCCESS,
    data,
  }),

  apiError: (errorMessage) => ({
    type: actions.API_ERROR,
    err: errorMessage,
  }),

  clearMessageError: () => ({
    type: actions.CLEAR_MESSAGE_ERROR,
  }),
};

export default actions;
