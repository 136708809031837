/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "./../../assets/svgs/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css";
import { setFiltersListPage } from "../../redux/companies/actionCreator";
import { useDispatch, useSelector } from "react-redux";

const DateRangePicker = ({
  filtering,
  handleFilterChange,
  clearFilters,
  clickedClearFilters,
  setPage,
}) => {
  const { filters } = useSelector((state) => state.Companies);
  const [startDate, setStartDate] = useState(filters?.start || null);
  const [endDate, setEndDate] = useState(filters?.end || null);
  const [isOpen, setIsOpen] = useState(null);
  const datePickerRef = useRef(null);
  const dispatch = useDispatch();

  const handleCalendarClose = () => setIsOpen(false);
  const handleCalendarOpen = () => setIsOpen(true);

  const { darkMode } = useSelector((state) => state.Themes);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const returnDates = {
        start: startDate,
        end: endDate,
      };
      handleFilterChange(filtering, returnDates);
    }
  }, [isOpen]);

  useEffect(() => {
    setStartDate(filters?.start);
    setEndDate(filters?.end);
  }, [filters]);

  const handleDateChange = (dates) => {
    setPage(0);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const returnDates = {
        start: start,
        end: end,
      };
      handleFilterChange(filtering, returnDates);
      dispatch(setFiltersListPage(returnDates));
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (clickedClearFilters) {
      clearFilters();
      setStartDate(null);
      setEndDate(null);
    }
    setIsOpen(null);
  }, [clickedClearFilters]);

  return (
    <div>
      <div
        className="p-2 dark:bg-[#2E3548] bg-white rounded-md h-[35px] mt-[0.5px] whitespace-nowrap border-[1px] dark:border-[#32394E] border-solid border-[#CED4DA]"
        ref={datePickerRef}
        style={{}}
      >
        <div
          className="flex flex-row justify-between gap-2 cursor-pointer"
          onClick={handleCalendarOpen}
        >
          {startDate && endDate ? (
            <div className="mt-[2px] text-xs font-normal dark:text-white text-black">
              {startDate.toDateString()} - {endDate.toDateString()}
            </div>
          ) : (
            <div className="text-xs font-normal dark:text-white text-black mt-[1px]">
              Select Date Range
            </div>
          )}
          <div className="mr-3">
            <span>
              <CalendarIcon />
            </span>
          </div>
        </div>
        {isOpen && (
          <div className={`absolute mt-4 ml-[-7px] ${!darkMode && "light-datepicker"}`}>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              onCalendarClose={handleCalendarClose}
              onCalendarOpen={handleCalendarOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePicker;
