import React from "react";
import Button from "../Button/Button";
import { ReactComponent as CloseButton } from "./../../assets/svgs/close-button-light-mode.svg";
import { DialogContent } from "@mui/material";

const DeleteCompany = ({
  icon,
  title,
  content,
  button,
  togglePopup,
  deleteCurrentCompany,
}) => {
  return (
    <DialogContent sx={{ padding: 0 }} className="bg-white text-black dark:bg-[#2A3042]">
      <div className="flex flex-col text-center p-4 relative ">
        <div className="flex justify-center items-center relative">
          <div className="absolute right-0 top-0">
            <CloseButton className="cursor-pointer" onClick={togglePopup} />
          </div>
          <div className="flex justify-center items-center w-full">{icon}</div>
        </div>

        <div className="mt-2 font-semibold text-xl dark:text-white text-black">{title}</div>
        <div className="mt-2 text-sm font-medium dark:text-[#C0C9E1] text-[#333B4E]">{content}</div>
      </div>
      <div className="flex flex-row justify-center m-4 gap-4">
        <Button
          backgroundColor="#556EE6"
          borderColour="#556EE6"
          textColor="#F8F8F8"
          hoverTextColor="#556EE6"
          textContent="Cancel"
          togglePopup={togglePopup}
        />
        <Button
          backgroundColor="#556EE6"
          borderColour="#556EE6"
          textColor="#F8F8F8"
          hoverTextColor="#556EE6"
          textContent={button}
          togglePopup={deleteCurrentCompany}
        />
      </div>
    </DialogContent>
  );
};

export default DeleteCompany;
