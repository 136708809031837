import React, { useState } from "react";

const Button = ({
  borderColour,
  textColor,
  textContent,
  togglePopup,
  backgroundColor,
  hoverTextColor,
  widthStatus,
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const getBackgroundColor = () => {
    if (isHovered) {
      if (
        backgroundColor === "var(--dropdown-icon-color)" ||
        backgroundColor === "var(--secondary-color)"
      )
        return "var(--hover-secondary-color)";
      else if (backgroundColor === "var(--tooltip-price-color)")
        return "var(--hover-price-color)";
      else return "var(--tooltip-heading-bg-color)";
    } else return backgroundColor;
  };

  const getDisabledColor = () => {
    if (
      backgroundColor === "var(--dropdown-icon-color)" ||
      backgroundColor === "var(--secondary-color)"
    )
      return "var(--hover-secondary-color)";
    else if (backgroundColor === "var(--tooltip-price-color)")
      return "var(--hover-price-color)";
    else return "var(--tooltip-heading-bg-color)";
  };

  return (
    <button
      className={`${widthStatus && 'w-full'} rounded-md h-9 whitespace-nowrap py-0 px-2 min-w-[76px] ${
        !disabled && "cursor-pointer"
      }`}
      style={{
        border: borderColour ? `1.5px solid ${borderColour}` : "none",
        background: disabled ? getDisabledColor() : getBackgroundColor(),
        outline: "none",
      }}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={togglePopup}
    >
      <span
        className="font-semibold text-[13px]"
        style={{
          color: isHovered && hoverTextColor ? hoverTextColor : textColor,
        }}
      >
        {textContent}
      </span>
    </button>
  );
};

export default Button;
