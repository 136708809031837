import React from "react";
import Instructions from "../../components/Instructions/Instructions";
import LoginForm from "../../components/LoginForm/LoginForm";

const LoginPage = () => {
  return (
    <div className="flex md:flex-row flex-col-reverse bg-white w-screen md:h-[100vh] overflow-scroll">
      <Instructions />
      <LoginForm />
    </div>
  );
};

export default LoginPage;
