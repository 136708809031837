// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table {
    min-height: 500px !important;
}
@media(max-width: 1280px) {
    .data-table {
        height: calc(100vh - 485px) !important;
    }
}
.customer-type {
    max-width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/components/DataTable/DataTable.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;AACA;IACI;QACI,sCAAsC;IAC1C;AACJ;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".data-table {\n    min-height: 500px !important;\n}\n@media(max-width: 1280px) {\n    .data-table {\n        height: calc(100vh - 485px) !important;\n    }\n}\n.customer-type {\n    max-width: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
