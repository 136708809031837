/* eslint-disable */
import React, { useState, useEffect } from "react";
import DataTableBottom from "../DataTableFooter/DataTableFooter";
import DataTableHeader from "../DataTableHeader/DataTableHeader";
import { Link } from "react-router-dom";
import EditableInput from "../EditableInput/EditableInput";
import { jwtDecode } from "jwt-decode";
import Button from "../Button/Button";
import NoCompanyFound from "../NoCompanyFound/NoCompanyFound";
import { useSelector } from "react-redux";
import { setFiltersListPage } from "../../redux/companies/actionCreator";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useFilters,
} from "react-table";
import RenderIf from "../RenderIf/RenderIf";
import DataTableActions from "../DataTableActions/DataTableActions";
import PaymentStatus from "./../PaymentStatus/PaymentStatus";
import { ColorRing } from "react-loader-spinner";
import "./DataTable.css";
import { toast } from "react-toastify";

function DataTable() {
  const { companies, filters, success, error, loading } = useSelector(
    (state) => state.Companies
  );

  useEffect(() => {
    setData(companies);
  }, [companies]);

  const [data, setData] = useState(companies);
  const [filteredData, setFilteredData] = useState(companies);

  const [clickedClearFilters, setClickedClearFilters] = useState(false);

  const handleFilterChange = (columnId, value) => {
    setFiltersListPage({ [columnId]: value });
  };

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        Header: "Company",
        accessor: "name",
        Cell: ({ row }) => {
          const {
            company_id,
            name,
            logo_url: image,
            location_id,
          } = row.original;
          return (
            <div className="flex flex-col dark:text-white text-black text-left font-normal mx-4">
              <div className="flex flex-row place-items-center">
                <div className="rounded-full mr-1 overflow-hidden bg-white border-solid border-[1px] border-[#9dA6C4]">
                  <img
                    src={image}
                    alt="Company logo"
                    className="rounded-full w-5 h-5 min-h-5 min-w-5"
                  />
                </div>
                <Link
                  to={`/companies?${
                    location_id
                      ? `location_id=${location_id}`
                      : `company_id=${company_id}`
                  }`}
                  className="text-[#556EE6]"
                >
                  {name}
                </Link>
              </div>
              <div className="flex flex-row text-xs mt-1">
                <span className="dark:text-[#A6B0CF] text-[#333B4E] mr-[2px]">
                  ID:{" "}
                </span>
                {location_id || company_id}
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Details",
        accessor: "userAgent",
        Cell: ({ row }) => {
          const { email, phone, website } = row.original;
          return (
            <div className="flex flex-col dark:text-white text-black text-left font-normal my-1">
              <div>
                <span className="dark:text-[#A6B0CF] text-[#333B4E]">
                  Email:{" "}
                </span>
                <span>{email}</span>
              </div>
              <div>
                <span className="dark:text-[#A6B0CF] text-[#333B4E]">
                  Phone:{" "}
                </span>
                <span>{phone || "-"}</span>
              </div>
              <div>
                <span className="dark:text-[#A6B0CF] text-[#333B4E]">
                  Website:{" "}
                </span>
                <span>
                  {(website && website.includes("http") ? (
                    <a target="_blank" href={website}>
                      {website}
                    </a>
                  ) : (
                    <a target="_blank" href={"https://".concat(website)}>
                      {website}
                    </a>
                  )) || "-"}
                </span>
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Installed On",
        accessor: "date_added",
        Cell: ({ row }) => {
          const { date_added } = row.original;
          return (
            <div className="items-center text-left">
              {new Date(date_added).toISOString().split("T")[0]}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ row }) => {
          const { address } = row.original;
          return (
            <div className="items-center w-[200px] text-left">{address}</div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Customer Count",
        accessor: "onboarding_info.customerCount",

        Cell: ({ row }) => {
          const { onboarding_info } = row.original;
          return (
            <div className="w-[100px] items-center">
              {onboarding_info?.customerCount || "-"}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Payment Link",
        accessor: "payment_link",
        Cell: ({ row }) => {
          const { payment_link, id, customer_type } = row.original;
          return (
            <RenderIf isTrue={customer_type != "agency"} fallback={<>-</>}>


              <RenderIf
                isTrue={user?.role == "admin"}
                fallback={
                  <div className="flex items-center space-x-2 dark:bg-[#2a3142] bg-white text-[#556EE6] w-[200px]">
                    <span className="truncate">
                      <RenderIf
                        isTrue={payment_link}
                        fallback={"N/A"}
                      ></RenderIf>
                      <div className="truncate max-w-full">
                        <a
                          target="_blank"
                          href={`${
                            payment_link?.includes("http")
                              ? payment_link
                              : "https://".concat(payment_link)
                          }`}
                        >
                          {payment_link}
                        </a>
                      </div>
                    </span>
                  </div>
                }
              >
                <EditableInput
                  initialValue={payment_link}
                  id={id}
                  field={"payment_link"}
                />
              </RenderIf>
            </RenderIf>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Agency Dashboard",
        accessor: "agency_dashboard",
        Cell: ({ row }) => {
          const { agency_dashboard, id, customer_type } = row.original;
          return (
            <RenderIf
              isTrue={customer_type != "agency"}
              fallback={<div className="text-left">-</div>}
            >
              <RenderIf
                isTrue={user?.role == "admin"}
                fallback={
                  <div className="flex items-center space-x-2 dark:bg-[#2a3142] bg-white text-[#556EE6] w-[200px]">
                    <span className="truncate">
                      <RenderIf
                        isTrue={agency_dashboard}
                        fallback={
                          <div className="dark:text-white text-black">N/A</div>
                        }
                      >

                      <div className="truncate max-w-full">
                        <a
                          target="_blank"
                          href={`${
                            agency_dashboard.includes("http")
                              ? agency_dashboard
                              : "https://".concat(agency_dashboard)
                          }`}
                        >
                          View Report
                        </a>
                      </div>
                    </RenderIf>
                    </span>
                    </div>
                }
              >
                <EditableInput
                  initialValue={agency_dashboard}
                  id={id}
                  field={"agency_dashboard"}
                />
              </RenderIf>
            </RenderIf>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Customer Type",
        accessor: "customer_type",
        Cell: ({ value }) => (
          <span className="text-xs text-[#FD7923] font-medium px-2 py-1 rounded-full dark:bg-[#3f373f] bg-[#fff2e9] whitespace-nowrap flex justify-start customer-type">
            {value}
          </span>
        ),
        disableSortBy: true,
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        Cell: ({ row }) => {
          const { payment_status, id, customer_type } = row.original;
          const textColorClass =
            payment_status == "Paid"
              ? "text-[#34C38F] dark:bg-[#2B3F4A] bg-[#ebf9f4] rounded-full"
              : payment_status == "Cancelled"
              ? "text-[#F46A6A] dark:bg-[#3E3646] bg-[#fef0f0] rounded-full"
              : "text-[#ff9801] bg-[#FFF2E9] dark:bg-[#3f3a3c] rounded-full";

          return (
            <RenderIf isTrue={customer_type != "agency"} fallback={<>-</>}>
              <RenderIf
                isTrue={user?.role == "agency"}
                fallback={<PaymentStatus value={payment_status} id={id} />}
              >
                <div className={textColorClass}>
                  <span className="flex text-xs font-medium px-2 py-1 whitespace-nowrap justify-around">
                    {payment_status}
                  </span>
                </div>
              </RenderIf>
            </RenderIf>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex gap-2">
            <DataTableActions
              companyId={row.original.company_id}
              locationId={row.original.location_id}
              setClickedClearFilters={setClickedClearFilters}
              clickedClearFilters={clickedClearFilters}
              filteredData={filteredData}
            />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Make Payment",
        Cell: ({ row }) => {
          const { payment_link, id, customer_type, payment_status } = row.original;
          return (
            <RenderIf
              isTrue={customer_type != "agency"}
              fallback={<div className="text-left">-</div>}
            >
              <div className="text-left">
                <RenderIf isTrue={payment_status=="Unpaid"} fallback={                  
                  <RenderIf isTrue={payment_status=="Cancelled"} fallback={<>Subscribed</>}>
                    Cancelled
                  </RenderIf>                                                  
                  }>
                <Button
                  backgroundColor="#556EE6"
                  textColor="white"
                  hoverTextColor="#647adb"
                  textContent="Pay"
                  togglePopup={() =>
                  {
                    if (payment_link) window.location.href = payment_link.startsWith("http") ? payment_link : `https://${payment_link}`; 
                    else toast.error("No Payment Link added yet!")
                  }
                  }
                />
                </RenderIf>
              </div>
            </RenderIf>
          );
        },
        disableSortBy: true,
      },
    ];
    const token = localStorage.getItem("token");
    const user = jwtDecode(token); // Decode token to get user details
    if (user?.role == "admin")
      return baseColumns.filter((_, index) => index != 10);
    return baseColumns.filter(
      (_, index) => (index < 2 || index > 5) && index != 7
    );
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageIndex: filters?.pageIndex,
        pageSize: filters?.pageSize,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const clearFilters = () => {
    setFiltersListPage({
      status: "",
      name: "",
      date_added: "",
      agency: "",
      start: "",
      end: "",
    });
    gotoPage(0);
  };

  const getDateUsingTimeZone = (passed) => {
    const date_added_1 = passed;
    const date1 = new Date(date_added_1);
    const output1 = date1.toISOString().split("T")[0]; // Extract the date part and format
    return output1;
  };

  const getDateOnly = (passed, add = false) => {
    const date_added_2 = passed;
    const date2 = new Date(date_added_2);
    if (add) {
      date2.setDate(date2.getDate() + 1); // Add one day
    }
    const output2 = date2.toISOString().split("T")[0]; // Convert to UTC and format
    return output2;
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = companies;
      // Filter for general fields like status and name
      Object.keys(filters).forEach((columnId) => {
        if (filters[columnId] && columnId !== "date_added") {
          filtered = filtered.filter((row) => {
            const value = row[columnId];
            if (columnId == "payment_status") {
              const payment_status = value;
              return payment_status
                ? payment_status.toString().toLowerCase() ==
                    filters[columnId].toLowerCase() &&
                    row.customer_type != "agency"
                : true;
            }
            return value
              ? value
                  .toString()
                  .toLowerCase()
                  .includes(filters[columnId].toLowerCase())
              : true;
          });
        }
      });
      if (filters.start && filters.end) {
        // Create start and end dates from filters and add one day
        const startDateString = new Date(getDateOnly(filters.start));
        const endDateString = new Date(getDateOnly(filters.end, true));
        filtered = filtered.filter((row) => {
          const rowDate = new Date(getDateUsingTimeZone(row.date_added));
          rowDate.setHours(0, 0, 0, 0);
          return rowDate >= startDateString && rowDate <= endDateString;
        });
      }
      if (filters.agency) {
        const company = companies.filter((row) => {
          return row.name == filters.agency;
        });
        if (company) {
          filtered = filtered.filter((row) => {
            return (
              row.company_id == company[0]?.company_id &&
              row.customer_type != "agency"
            );
          });
        }
      }
      setFilteredData(filtered);
    };
    applyFilters();
  }, [filters, companies]);

  return (
    <div className="w-full">
      <DataTableHeader
        handleFilterChange={handleFilterChange}
        clearFilters={clearFilters}
        clickedClearFilters={clickedClearFilters}
        setClickedClearFilters={setClickedClearFilters}
        setPage={gotoPage}
      />
      <RenderIf
        isTrue={success && filteredData.length !== 0}
        fallback={
          <RenderIf isTrue={loading}>
            <div className="flex items-center justify-center h-screen">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </div>
          </RenderIf>
        }
      >
        <div className="m-6 p-6 flex flex-col dark:bg-[#2A3042] bg-white">
          <div
            className="w-full overflow-x-auto h-[500px] data-table"
            style={{
              height: "calc(100vh - 450px)",
            }}
          >
            <table {...getTableProps()} className="w-full overflow-x-auto">
              <thead className="dark:bg-[#32384B] bg-[#F8F9FA] h-[44px] pl-3 p-6">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`text-xs leading-6 font-semibold dark:text-[#C3CBE4] text-[#74798C] whitespace-nowrap text-left pr-[24px] ${
                          column.render("Header") == "Company" && "pl-[16px]"
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="overflow-y-auto">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="font-light py-2 px-3 items-center text-center h-28 border-b-[1px] dark:border-[#30384A] border-[#E0E0E0]"
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="text-xs dark:text-[#A6B0CF] text-black pr-[24px]"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <DataTableBottom
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            pageCount={pageCount}
            setPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            data={page}
            totalRecords={filteredData.length} // Pass the dynamically calculated total records
          />
        </div>
      </RenderIf>
      <RenderIf isTrue={!success && error}>
        <NoCompanyFound title="Error from API!" />
      </RenderIf>
      <RenderIf
        isTrue={!loading && !error && success && filteredData.length === 0}
      >
        <NoCompanyFound title="No companies found!" />
      </RenderIf>
    </div>
  );
}

export default DataTable;
