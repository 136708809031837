import React from "react";

const Instructions = () => {
  return (
    <div className="w-full md:w-1/2 flex flex-col px-[30px] lg:px-[60px] pt-[40px] pb-[40px] max-w-[800px] rounded-tr-[50px] rounded-br-[50px] bg-[#EEF1FE] overflow-scroll">
      <div>
        <span>
          <img
            src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
            alt="alternatetext"
            className="h-12"
          ></img>
        </span>
      </div>

      <div className="font-semibold text-2xl mt-[40px]">
        Thanks for Installing our White Label SEO App!
      </div>

      <div className="font-semibold text-lg mt-[24px]">Instructions</div>
      <div className="mt-[12px]">
        <ul className="list-disc pl-5">
          <li>
            To get your clients started with our SEO services, sign up on our
            application using the same agency name, email, and address you used
            on the Go High Level platform.
          </li>
          <li>
            Once signed in, you'll see the details of all your sub-accounts, and
            you can begin working with them.
          </li>
          <li className="break-all">
              {
                "You will have to add https://auth.sceptermarketing.com/dashboard-link/{{location.id}} as a custom link for all your sub-accounts"
              }
          </li>
          <li>
            Click on Pay Button for that sub-account you want to offer SEO
            serivices and make a payment{" "}
          </li>
          <li>It will take some time on our end to verify the payment</li>
          <li>
            Once, we verify the payment you will see the status of you
            sub-account as paid
          </li>
          <li>
            The sub-account can view his dashboard from the Go high level
            platform tab that you have added as a custom link
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Instructions;
