import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { fetchLoggedInUser } from "../../redux/agencies/actionCreator";
import { useDispatch } from "react-redux";

const AuthGuard = ({ children }) => {
  const token = localStorage.getItem("token");
  let isAuthenticated = false;
  let user = null;
  const dispatch = useDispatch();

  if (token) {
    try {
      user = jwtDecode(token); // Decode token to get user details
      isAuthenticated = true;
    } catch (error) {
      console.error("Invalid token", error);
      localStorage.removeItem("token"); // Remove invalid token
    }
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  dispatch(fetchLoggedInUser(user));

  console.log("Authenticated user:", user); // You can access user details here

  return children;
};

export default AuthGuard;
