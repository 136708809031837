import actions from "./actions";
import api from "../../utils/apiData";
import { isSuccess } from "../../utils/commonMethods";

const {
  fetchStatusesBegin,
  fetchStatusesSuccess,
  apiError,
  clearMessageError,
} = actions;

export const fetchStatuses = (params) => async (dispatch) => {
  try {
    dispatch(fetchStatusesBegin());
    const response = await api.companies.getAllStatuses();
    if (isSuccess(response))
      dispatch(fetchStatusesSuccess(response.data.statuses));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const clearMessage = () => async (dispatch) => {
  dispatch(clearMessageError());
};
