const actions = {
  FETCH_NAMES_BEGIN: "FETCH_NAMES_BEGIN",
  FETCH_NAMES_SUCCESS: "FETCH_NAMES_SUCCESS",

  API_ERROR: "API_ERROR",
  CLEAR_MESSAGE_ERROR: "CLEAR_MESSAGE_ERROR",

  fetchNamesBegin: () => ({
    type: actions.FETCH_NAMES_BEGIN,
  }),

  fetchNamesSuccess: (data) => ({
    type: actions.FETCH_NAMES_SUCCESS,
    data,
  }),

  apiError: (errorMessage) => ({
    type: actions.API_ERROR,
    err: errorMessage,
  }),

  clearMessageError: () => ({
    type: actions.CLEAR_MESSAGE_ERROR,
  }),
};

export default actions;
