import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LightMode } from "./../../assets/svgs/light-mode.svg";
import { ReactComponent as DarkMode } from "./../../assets/svgs/dark-mode.svg";
import {
  toggleThemeAction,
  applySavedTheme,
} from "../../redux/themes/actionCreator";
import RenderIf from "../RenderIf/RenderIf";

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.Themes.darkMode);

  const handleToggle = () => {
    dispatch(toggleThemeAction(darkMode));
  };

  useEffect(() => {
    dispatch(applySavedTheme());
  }, [dispatch]);

  return (
    <div className="px-4 py-1 text-black dark:text-white rounded-md">
      <RenderIf
        isTrue={darkMode}
        fallback={
          <LightMode onClick={handleToggle} className="cursor-pointer" />
        }
      >
        <DarkMode onClick={handleToggle} className="cursor-pointer" />
      </RenderIf>
    </div>
  );
};

export default ThemeToggle;
