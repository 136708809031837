/* eslint-disable default-param-last */
import actions from "./actions";

const {
  API_ERROR,
  CLEAR_MESSAGE_ERROR,
  FETCH_COMPANIES_BEGIN,
  FETCH_COMPANIES_SUCCESS,
  FETCH_AGENCY_EMAIL_BEGIN,
  FETCH_AGENCY_EMAIL_SUCCESS,
  FETCH_COMPANY_BEGIN,
  FETCH_COMPANY_SUCCESS,
  FETCH_SUB_ACCOUNT_BEGIN,
  FETCH_SUB_ACCOUNT_SUCCESS,
  DELETE_COMPANY_BEGIN,
  DELETE_COMPANY_SUCCESS,
  DELETE_SUB_ACCOUNT_BEGIN,
  DELETE_SUB_ACCOUNT_SUCCESS,
  UPDATE_COMPANY_BEGIN,
  UPDATE_COMPANY_SUCCESS,
  SET_FILTERS,
  SET_SEARCH_QUERY,
  CLEAR_FILTERS,
} = actions;

const initState = {
  companies: [],
  currentCompany: {},
  agencyEmail: {},
  total: 0,
  filters: {},
  searchQuery: "",
  loading: false,
  success: null,
  error: null,
};

const Companies = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCH_COMPANIES_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: data,
        total: data.length,
        loading: false,
        success: true,
      };
    case FETCH_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        currentCompany: data,
        loading: false,
        success: true,
      };
    case FETCH_SUB_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_AGENCY_EMAIL_BEGIN:
      return {
        ...state,
        agencyEmail: {},
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_AGENCY_EMAIL_SUCCESS:
      return {
        ...state,
        agencyEmail: data,
        loading: false,
        success: true,
      };
    case FETCH_SUB_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentCompany: data,
        loading: false,
        success: true,
      };

    case UPDATE_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map((company) =>
          company.id === data.id ? data : company
        ),
        total: data.length,
        loading: false,
        success: true,
      };
    case DELETE_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: data.toLowerCase(),
      };
    case DELETE_COMPANY_SUCCESS: {
      const updatedCompanies = state.companies.filter(
        (company) => company.id !== data.company.id
      );
      return {
        ...state,
        companies: updatedCompanies,
        total: updatedCompanies.length,
        loading: false,
        success: true,
      };
    }
    case DELETE_SUB_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case DELETE_SUB_ACCOUNT_SUCCESS: {
      const updatedCompanies = state.companies.filter(
        (company) => company.id !== data.company.id
      );
      return {
        ...state,
        companies: updatedCompanies,
        total: updatedCompanies.length,
        loading: false,
        success: true,
      };
    }
    case SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...data },
      };
    case CLEAR_FILTERS:
      return { ...state, filters: initState.filters };
    case API_ERROR:
      return { ...state, loading: false, error: err, success: false };
    case CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        success: null,
        error: null,
        filters: {},
        searchQuery: "",
      };
    default:
      return state;
  }
};

export default Companies;
