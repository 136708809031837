import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ListPage from "../../pages/ListPage/ListPage";
import DetailsPage from "../../pages/DetailsPage/DetailsPage";
import LoginPage from "./../../pages/LoginPage/LoginPage";
import SignupPage from "./../../pages/SignupPage/SignupPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthGuard from "../../components/AuthGuard/AuthGuard";
import DashboardLinkPage from "../../pages/DashboardLinkPage/DashboardLinkPage";

const MainLayout = () => {
  return (
    <BrowserRouter>
      <ToastContainer theme="colored" />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/dashboard-link/:location_id"
          element={<DashboardLinkPage />}
        />
        <Route
          path="/"
          element={
            <AuthGuard>
              <ListPage />
            </AuthGuard>
          }
        />
        <Route
          path="/companies"
          element={
            <AuthGuard>
              <DetailsPage />
            </AuthGuard>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MainLayout;
