import React from "react";
import RenderIf from "../RenderIf/RenderIf";

const CompanyField = ({ title, content }) => {
  return (
    <RenderIf isTrue={content !== "-"}>
      <div className="flex flex-col font-normal text-[13px] mt-[24px]">
        <div className="dark:text-[#A6B0CF] text-[#74798C]">{title}</div>
        <div className="relative group">
          <div
            className={`${
              title === "Website" || title === "Privacy Policy"
                ? "text-[#556EE6]"
                : title === "Customer Type"
                ? "text-[#FD7923]"
                : "dark:text-white text-[#333B4E]"
            }`}
          >
            <RenderIf
              isTrue={title === "Website" || title === "Privacy Policy"}
              fallback={<div className={`truncate max-w-full  ${title == "Payment" ?  content == 'Paid' ? 'text-[#34C38F]' :  content == 'Unpaid' ? 'text-[#F46A6A]': 'text-red-500' : ''}`}>{content}</div>}
            >
              <div className="truncate max-w-full"><a target="_blank" href={`${ content.includes("http") ? content : "https://".concat(content)}`}>{content}</a></div>
            </RenderIf>

            <div className={`absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block w-max bg-[#222737] text-white text-xs rounded py-1 px-2 `}>
              {content}
            </div>
          </div>
        </div>
      </div>
    </RenderIf>
  );
};

export default CompanyField;
