import React, { useEffect } from "react";
import Instructions from "../../components/Instructions/Instructions";
import SignupForm from "../../components/SignupForm/SignupForm";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { clearMessage } from "../../redux/companies/actionCreator";
import { useDispatch } from "react-redux";

const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the user is already authenticated
    dispatch(clearMessage());
    const token = localStorage.getItem("token");
    if (token) {
      try {
        jwtDecode(token); // If token is valid, redirect to the home page
        navigate("/"); // or any other protected route
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, []);

  return (
    <div className="flex md:flex-row flex-col-reverse bg-white w-screen min-h-[100vh]">
      <Instructions />
      <SignupForm />
    </div>
  );
};

export default SignupPage;
