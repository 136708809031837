import React from "react";

const Footer = () => {
  return (
    <div className="dark:bg-[#2A3042] dark:text-[#74788D] text-[#74788D] bg-white font-normal text-sm py-4 pl-6">
      2024 © Scepter
    </div>
  );
};

export default Footer;
