import actions from "./actions";
import api from "../../utils/apiData";
import { isSuccess } from "../../utils/commonMethods";

const { fetchNamesBegin, fetchNamesSuccess, apiError, clearMessageError } =
  actions;

export const fetchNames = () => async (dispatch) => {
  try {
    dispatch(fetchNamesBegin());
    const response = await api.companies.getAllNames();
    if (isSuccess(response)) dispatch(fetchNamesSuccess(response.data.names));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const clearMessage = () => async (dispatch) => {
  dispatch(clearMessageError());
};
