/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as FirstPageIcon } from "./../../assets/svgs/first-page.svg";
import { ReactComponent as LastPageIcon } from "./../../assets/svgs/last-page.svg";
import { ReactComponent as LeftPageIcon } from "./../../assets/svgs/left-page.svg";
import { ReactComponent as RightPageIcon } from "./../../assets/svgs/right-page.svg";
import { useDispatch,useSelector } from "react-redux";
import { setFiltersListPage } from "../../redux/companies/actionCreator";

const PaginationComponent = ({
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageIndex,
  pageCount,
  setPage,
  pageSize,
}) => {
  const [buttonsToShow] = useState(5);
  const dispatch = useDispatch();
  const {
    filters
  } = useSelector((state) => state.Companies);


  const startPage = Math.max(
    1,
    Math.min(
      pageIndex + 1 - Math.floor(buttonsToShow / 2),
      pageCount - buttonsToShow + 1
    )
  );
  const endPage = Math.min(pageCount, startPage + buttonsToShow - 1);

  useEffect(() => {
    dispatch(
      setFiltersListPage({
        pageIndex: pageIndex,
        pageCount: pageCount,
        pageSize: pageSize,
      })
    );
  }, [pageIndex, pageCount, pageSize]);


  useEffect(()=>{
    setPage(filters?.pageIndex);
  },[])

  const paginationLinks = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationLinks.push(
      <li key={i}>
        <button
          type="button"
          onClick={() => setPage(i - 1)}
          className={`flex h-10 min-w-10 items-center justify-center px-3 ${
            i === pageIndex + 1
              ? "border dark:border-[#313a4d] border-[#CED4DA] bg-[#556EE6] text-white hover:bg-blue-100 hover:text-blue-custom"
              : "border dark:border-[#313a4d] border-[#CED4DA] dark:bg-[#2E3648] bg-white text-[#C3CBE4] hover:bg-gray-100 hover:text-[#556EE6] "
          }`}
        >
          {i}
        </button>
      </li>
    );
  }

  return (
    <div className="mt-5 font-medium">
      <div className="mr-0 mt-5 flex flex-col items-center justify-center pb-10 sm:items-end h-2">
        <nav aria-label="Page navigation example dark:bg-[#2E3648] bg-white dark:bg-gray-700">
          <ul className="inline-flex -space-x-px text-[13px]">
            <li>
              <button
                type="button"
                onClick={() => setPage(0)}
                className="hidden h-10 min-w-10 items-center justify-center rounded-l-lg border dark:border-[#313a4d] border-[#CED4DA] dark:bg-[#2E3648] bg-white px-1 leading-tight text-[#C3CBE4] hover:bg-gray-100 hover:text-gray-700 sm:px-3 md:flex"
                disabled={!canPreviousPage}
              >
                <FirstPageIcon />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => previousPage()}
                className={`flex h-10 min-w-10 items-center justify-center px-3 border dark:border-[#313a4d] border-[#CED4DA] dark:bg-[#2E3648] bg-white text-[#C3CBE4] hover:bg-gray-100 hover:text-gray-700`}
                disabled={!canPreviousPage}
              >
                <LeftPageIcon />
              </button>
            </li>
            {paginationLinks}
            <li>
              <button
                type="button"
                onClick={() => nextPage()}
                className={`flex h-10 min-w-10 items-center justify-center px-3 border dark:border-[#313a4d] border-[#CED4DA] dark:bg-[#2E3648] bg-white text-[#C3CBE4] hover:bg-gray-100 hover:text-gray-700`}
                disabled={!canNextPage}
              >
                <RightPageIcon />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => setPage(pageCount - 1)}
                className="md:flex hidden h-10 min-w-10 items-center justify-center rounded-r-lg border dark:border-[#313a4d] border-[#CED4DA] dark:bg-[#2E3648] bg-white px-1 leading-tight text-[#C3CBE4] hover:bg-gray-100 hover:text-gray-700 sm:px-3"
                disabled={!canNextPage}
              >
                <LastPageIcon />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

PaginationComponent.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default PaginationComponent;
