import React from "react";
import DataTable from "../../components/DataTable/DataTable";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCompanies } from "../../redux/companies/actionCreator";
import { fetchStatuses } from "../../redux/statuses/actionCreator";
import { fetchNames } from "../../redux/names/actionCreator";
import { fetchAgencies } from "../../redux/agencies/actionCreator";

function ListPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStatuses());
    dispatch(fetchNames());
    dispatch(fetchAgencies());
  }, [dispatch]);

  return (
    <div className="min-h-screen flex flex-col dark:bg-[#2A3042] bg-white h-[100vh]">
      <Header />
      <div className=" dark:bg-[#222737] bg-[#f4f4f8] text-white">
        <div className="w-full">
          <DataTable />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ListPage;
