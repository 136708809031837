/* eslint-disable default-param-last */
import actions from "./actions";

const {
  FETCH_AGENCIES_BEGIN,
  FETCH_AGENCIES_SUCCESS,
  LOGIN_AGENCY_BEGIN,
  LOGIN_AGENCY_SUCCESS,
  SIGNUP_AGENCY_BEGIN,
  SIGNUP_AGENCY_SUCCESS,
  FETCH_LOGGED_IN_USER_BEGIN,
  FETCH_LOGGED_IN_USER_SUCCESS,
  API_ERROR,
  CLEAR_MESSAGE_ERROR,
} = actions;

const initState = {
  agencies: [],
  loggedInUser: {},
  total: 0,
  loading: false,
  success: null,
  error: null,
};

const Agencies = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_AGENCY_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case LOGIN_AGENCY_SUCCESS:
      return {
        ...state,
        loggedInUser: data,
        loading: false,
        success: true,
      };
    case SIGNUP_AGENCY_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case SIGNUP_AGENCY_SUCCESS:
      return {
        ...state,
        loggedInUser: data,
        loading: false,
        success: true,
      };

    case FETCH_AGENCIES_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_AGENCIES_SUCCESS:
      return {
        ...state,
        agencies: data,
        total: data.length,
        loading: false,
        success: true,
      };
    case FETCH_LOGGED_IN_USER_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        loggedInUser: data,
        loading: false,
        success: true,
      };
    case API_ERROR:
      return { ...state, loading: false, error: err, success: false };
    case CLEAR_MESSAGE_ERROR:
      return { ...state, success: null, error: null, loggedInUser: {} };
    default:
      return state;
  }
};

export default Agencies;
