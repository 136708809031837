import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearMessage } from "../../redux/agencies/actionCreator";
import { clearMessage as clearMessageCompanies } from "../../redux/companies/actionCreator";
import { clearMessage as clearMessageStatuses } from "../../redux/statuses/actionCreator";
import { clearMessage as clearMessageNames } from "../../redux/names/actionCreator";
import { ReactComponent as LogoutIcon } from "./../../assets/svgs/logout.svg";
import { ReactComponent as LoginImage } from "./../../assets/svgs/login-image.svg";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = jwtDecode(token); // Decode token to get user details
    setCurrentUser(user);
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("token");
    dispatch(clearMessage());
    dispatch(clearMessageCompanies());
    dispatch(clearMessageStatuses());
    dispatch(clearMessageNames());
    toast.success("User logged out successfully!");
    localStorage.removeItem("modalShown");
    navigate("/login");
  };
  return (
    <div className="flex flex-row justify-between w-full h-[69px] dark:bg-[#262B3C] bg-[#556EE6]">
      <span>
        <img
          src="https://sceptermarketing.com/wp-content/uploads/2019/10/scepterlogo600.png"
          alt="alternatetext"
          className="ml-5 m-4 h-10"
        ></img>
      </span>
      <div className="mx-6 my-4 flex justify-center items-center">
        <ThemeToggle />
        <div className="flex flex-row border-r-[1px] border-solid dark:border-[#38425B] border-white ml-[12px]">
          <LoginImage />
          <div className="flex flex-col text-white dark:text-[#C0C9E1] font-medium text-sm ml-[10px] mr-[24px]">
            <div>{currentUser?.email}</div>
            <div>
              {currentUser?.role == "admin" ? "Admin User" : "Agency User"}
            </div>
          </div>
        </div>{" "}
        <div className="flex justify-center pl-[24px]">
          <div className="flex cursor-pointer" onClick={logoutUser}>
            <div>
              <LogoutIcon />
            </div>
            <div className="dark:text-[#C0C9E1] text-white font-medium text-sm ml-2 cursor-pointer">
              Logout
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Header;
