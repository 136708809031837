/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "./../../assets/svgs/delete-icon.svg";
import { ReactComponent as DeleteCompanyIcon } from "./../../assets/svgs/delete-strategy-icon.svg";
import { ReactComponent as ViewIcon } from "./../../assets/svgs/view.svg";
import DeleteCompany from "../DeleteCompany/DeleteCompany";
import { deleteCompany } from "../../redux/companies/actionCreator";
import { deleteSubAccount } from "../../redux/companies/actionCreator";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setFiltersListPage } from "../../redux/companies/actionCreator";
import { jwtDecode } from "jwt-decode";

import { Dialog } from "@mui/material";

const DataTableActions = ({
  companyId,
  locationId,
  setClickedClearFilters,
  clickedClearFilters,
  filteredData,
}) => {
  const [isDeleteIconOpen, setIsDeleteIconOpen] = useState(false);
  const [isViewIconOpen, setIsViewIconOpen] = useState(false);
  const [role,setRole] = useState(null);
  const dispatch = useDispatch();
  const { filters, total } = useSelector((state) => state.Companies);

  const toggleDeleteIcon = () => {
    setIsDeleteIconOpen(!isDeleteIconOpen);
  };

  const toggleViewIcon = () => {
    setIsViewIconOpen(!isViewIconOpen);
  };

  const deleteCurrentCompany = () => {
    if (locationId) {
      dispatch(deleteSubAccount(locationId));
      toast.success("Sub-account deleted successfully!");
    } else {
      dispatch(deleteCompany(companyId));
      toast.success("Company deleted successfully!");
    }
    if (
      filters?.pageSize * filters?.pageIndex ==
        (filteredData.length || total) - 1 &&
      filters?.pageIndex > 0
    ) {
      const newPageIndex = filters?.pageIndex - 1;
      dispatch(setFiltersListPage({ pageIndex: newPageIndex }));
    }
    setIsDeleteIconOpen(!isDeleteIconOpen);
    setClickedClearFilters(!clickedClearFilters);
  };

  useEffect(() => {
    // Check if the user is already authenticated
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const user = jwtDecode(token); // If token is valid, redirect to the home page
        setRole(user?.role)
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, []);

  return (
    <>
      <Link
        to={`/companies?${
          locationId ? `location_id=${locationId}` : `company_id=${companyId}`
        }`}
        className="text-[#556EE6]"
      >
        <span title="View Company">
          <ViewIcon className="cursor-pointer" onClick={toggleViewIcon} />
        </span>
      </Link>

      <span title="Delete Company" className={`${!locationId && role !== "admin" && 'invisible'}`}>
        <DeleteIcon onClick={toggleDeleteIcon} className="cursor-pointer" />
      </span>

      <Dialog
        open={isDeleteIconOpen}
        onClose={() => setIsDeleteIconOpen(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { borderRadius: "7px", background: "#2A3042" } }}
      >
        <DeleteCompany
          icon={<DeleteCompanyIcon />}
          title={"Are you sure you want to delete this company?"}
          content={"This company will be deleted from the system."}
          button={"Delete"}
          buttonColour="var(--tooltip-price-color)"
          togglePopup={toggleDeleteIcon}
          deleteCurrentCompany={deleteCurrentCompany}
        />
      </Dialog>
    </>
  );
};

export default DataTableActions;
