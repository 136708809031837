import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ReactComponent as CloseButtonLightMode } from "./../../assets/svgs/close-button-light-mode.svg";

const PopupWizard = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm" className="dark:text-white"  >
      <DialogTitle className="bg-white dark:bg-[#2A3042] ">
        <div className="flex justify-between items-center">
          <div>
            <span>
              <img
                src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
                alt="alternatetext"
                className="h-12"
              ></img>
            </span>
          </div>
          <IconButton onClick={handleClose}>
            <CloseButtonLightMode></CloseButtonLightMode>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className="bg-white dark:bg-[#2A3042] dark:text-white">
        <div className="font-semibold text-xl text-black dark:text-white">
          Thanks for Installing our White Label SEO App!
        </div>

        <div className="font-semibold text-lg text-black mt-[32px] dark:text-white">
          Insructions
        </div>
        <div className="text-sm font-normal text-black mt-[16px] dark:text-[#C0C9E1]">
          To get one of your client started with SEO services, just click on the
          Pay button for that sub-account and make a payment. We can work on any
          project starting at just $500/mo. For multi-location businesses, we
          recommend $500/mo/location. Our SEO Services are all inclusive and
          cover:
        </div>

        <div className="space-y-4 mt-[16px]">
          <ul className="list-disc pl-5 space-y-1 font-normal text-sm text-[#333B4E] dark:text-[#C0C9E1]">
            <li>SEO Audit</li>
            <li>Keyword Research</li>
            <li>On-Page Optimization</li>
            <li>Off-Page Optimization</li>
            <li>Technical SEO</li>
            <li>Content Creation</li>
            <li>Link Building</li>
            <li>Citations</li>
            <li>Results & Reporting</li>
          </ul>
          <p className="text-sm font-normal text-[#333B4E] mt-[24px] dark:text-[#C0C9E1]">
            <span className="font-bold">Note:</span> We have no contracts.
            Cancel at any time if you don't love the results. We do not charge
            any fees beyond the monthly subscription for the work done.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PopupWizard;
