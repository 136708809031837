import Companies from "./companies/reducer";
import CompanyNames from "./names/reducer";
import CompanyStatuses from "./statuses/reducer";
import Agencies from "./agencies/reducer";
import Themes from "./themes/reducer";

import { combineReducers } from "redux";

const appReducer = combineReducers({
  Companies,
  CompanyNames,
  CompanyStatuses,
  Agencies,
  Themes,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
