const actions = {
  FETCH_AGENCIES_BEGIN: "FETCH_AGENCIES_BEGIN",
  FETCH_AGENCIES_SUCCESS: "FETCH_AGENCIES_SUCCESS",
  LOGIN_AGENCY_BEGIN: "LOGIN_AGENCY_BEGIN",
  LOGIN_AGENCY_SUCCESS: "LOGIN_AGENCY_SUCCESS",
  SIGNUP_AGENCY_BEGIN: "SIGNUP_AGENCY_BEGIN",
  SIGNUP_AGENCY_SUCCESS: "SIGNUP_AGENCY_SUCCESS",

  FETCH_LOGGED_IN_USER_BEGIN: "FETCH_LOGGED_IN_USER_BEGIN",
  FETCH_LOGGED_IN_USER_SUCCESS: "FETCH_LOGGED_IN_USER_SUCCESS",

  API_ERROR: "API_ERROR",
  CLEAR_MESSAGE_ERROR: "CLEAR_MESSAGE_ERROR",

  fetchAgenciesBegin: () => ({
    type: actions.FETCH_AGENCIES_BEGIN,
  }),

  fetchAgenciesSuccess: (data) => ({
    type: actions.FETCH_AGENCIES_SUCCESS,
    data,
  }),

  fetchLoggedInUserBegin: () => ({
    type: actions.FETCH_LOGGED_IN_USER_BEGIN,
  }),

  fetchLoggedInUserSuccess: (data) => ({
    type: actions.FETCH_LOGGED_IN_USER_SUCCESS,
    data,
  }),

  signupAgencyBegin: () => ({
    type: actions.SIGNUP_AGENCY_BEGIN,
  }),

  signupAgencySuccess: (data) => ({
    type: actions.SIGNUP_AGENCY_SUCCESS,
    data,
  }),

  loginAgencyBegin: () => ({
    type: actions.LOGIN_AGENCY_BEGIN,
  }),

  loginAgencySuccess: (data) => ({
    type: actions.LOGIN_AGENCY_SUCCESS,
    data,
  }),

  apiError: (errorMessage) => ({
    type: actions.API_ERROR,
    err: errorMessage,
  }),

  clearMessageError: () => ({
    type: actions.CLEAR_MESSAGE_ERROR,
  }),
};

export default actions;
