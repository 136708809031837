/* eslint-disable default-param-last */
import actions from "./actions";

const {
  API_ERROR,
  CLEAR_MESSAGE_ERROR,
  FETCH_STATUSES_BEGIN,
  FETCH_STATUSES_SUCCESS,
} = actions;

const initState = {
  statuses: [],
  total: 0,
  loading: false,
  success: null,
  error: null,
};

const CompanyStatuses = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCH_STATUSES_BEGIN:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case FETCH_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: data,
        total: data.length,
        loading: false,
        success: true,
      };
    case API_ERROR:
      return { ...state, loading: false, error: err, success: false };
    case CLEAR_MESSAGE_ERROR:
      return { ...state, success: null, error: null };
    default:
      return state;
  }
};

export default CompanyStatuses;
