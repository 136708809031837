import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearMessage } from "../../redux/agencies/actionCreator";
import { clearMessage as clearMessageCompanies } from "../../redux/companies/actionCreator";
import { clearMessage as clearMessageNames } from "../../redux/names/actionCreator";
import { ReactComponent as LogoutIcon } from "./../../assets/svgs/logout.svg";
import { ReactComponent as LoginImage } from "./../../assets/svgs/login-image.svg";
import { ReactComponent as NavbarIcon } from "./../../assets/svgs/navbar-icon.svg";
import { ReactComponent as SettingsIcon } from "./../../assets/svgs/settings.svg";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import RenderIf from "../RenderIf/RenderIf";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState("");
  const [clickedNavbar, setClickedNavbar] = useState(false);
  const [clickedProfile, setClickedProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const navbarRef = useRef(null);
  const changePasswordRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = jwtDecode(token); // Decode token to get user details
    setCurrentUser(user);
    // Click outside handler
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setClickedNavbar(false);
      }
      if (
        changePasswordRef.current &&
        !changePasswordRef.current.contains(event.target)
      ) {
        setClickedProfile(false);
      }
    };

    // Attach listener for clicks outside the Navbar
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clickProfile = () => {
    setClickedProfile(!clickedProfile);
  };

  const handleNavbarClick = () => {
    setClickedNavbar(!clickedNavbar);
  };

  const clickedChangePassword = () => {
    console.log("Clicked on Change Password");
    setClickedProfile(!clickedProfile);
    navigate("/change-password");
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    dispatch(clearMessage());
    dispatch(clearMessageCompanies());
    dispatch(clearMessageNames());
    toast.success("User logged out successfully!");
    localStorage.removeItem("modalShown");
    navigate("/login");
  };

  const goToHomePage = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-row justify-between w-full h-[69px] dark:bg-[#262B3C] bg-[#556EE6]">
      <span>
        <img
          src="https://sceptermarketing.com/wp-content/uploads/2019/10/scepterlogo600.png"
          alt="alternatetext"
          className="ml-5 m-4 h-10 cursor-pointer"
          onClick={goToHomePage}
        ></img>
      </span>
      <div className="mx-6 my-4 sm:flex hidden justify-center items-center">
        <ThemeToggle />
        <div
          className="flex relative flex-row border-r-[1px] border-solid dark:border-[#38425B] border-white ml-[12px] cursor-pointer"
          onClick={clickProfile}
        >
          <LoginImage />
          <div className="flex flex-col text-white dark:text-[#C0C9E1] font-medium text-sm ml-[10px] mr-[24px]">
            <div>{currentUser?.email}</div>
            <div>
              {currentUser?.role == "admin" ? "Admin User" : "Agency User"}
            </div>
          </div>
          <div className="rotate-90 text-base text-white dark:text-[#C3CBE4] mr-[30px] ml-[-20px]">
            &#x276F;
          </div>
          <RenderIf isTrue={clickedProfile}>
            <div
              className="max-w-full absolute w-[92%] top-[46px] cursor-pointer"
              ref={changePasswordRef}
              onClick={clickedChangePassword}
            >
              <div className="flex top-12 w-full py-[16px] pl-[16px] pr-[40px] dark:bg-[#32384B] dark:text-[#C3CBE4] bg-white text-[#333B4E] font-normal text-xs rounded-md justify-center items-center">
                <SettingsIcon className="text-[#c3cbe4] stroke-current" />{" "}
                <span className="ml-2 whitespace-nowrap">Change Password</span>
              </div>
            </div>
          </RenderIf>
        </div>{" "}
        <div className="sm:flex justify-center pl-[24px] hidden">
          <div className="flex cursor-pointer" onClick={logoutUser}>
            <div>
              <LogoutIcon />
            </div>
            <div className="dark:text-[#C0C9E1] text-white font-medium text-sm ml-2 cursor-pointer">
              Logout
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="sm:hidden block self-center p-[24px] relative">
        <NavbarIcon onClick={handleNavbarClick} className="cursor-pointer" />
        <div ref={navbarRef}>
          <RenderIf isTrue={clickedNavbar} fallback={<></>}>
            <div className="mx-6 flex flex-col justify-center dark:bg-[#32384b] p-3 rounded-md absolute right-0 bg-white  z-[1] shadow-lg">
              <div className="flex flex-row bg-[#556ee6] dark:bg-[#262B3C] p-4 rounded-lg">
                <div className="flex flex-row border-solid dark:border-[#38425B] border-white mr-[12px]">
                  <LoginImage />
                  <div className="flex flex-col text-white dark:text-[#C0C9E1] font-medium text-sm ml-[10px]">
                    <div>{currentUser?.email}</div>
                    <div>
                      {currentUser?.role == "admin"
                        ? "Admin User"
                        : "Agency User"}
                    </div>
                  </div>
                </div>{" "}
                <ThemeToggle />
              </div>
              <div className="flex flex-col pt-[18px] space-y-2">
                <div
                  className="flex cursor-pointer pt-[12px]"
                  onClick={clickedChangePassword}
                >
                  <div>
                    <SettingsIcon className="text-[#c3cbe4] stroke-current" />
                  </div>
                  <div className="dark:text-[#C0C9E1] text-black font-medium text-sm ml-2 cursor-pointer">
                    Change Password
                  </div>
                </div>
                </div>
                <div
                  className="flex cursor-pointer pt-[24px] pb-[12px]"
                  onClick={logoutUser}
                >
                  <div>
                    <LogoutIcon />
                  </div>
                  <div className="dark:text-[#C0C9E1] text-black font-medium text-sm ml-2 cursor-pointer">
                    Logout
                  </div>
                </div>{" "}
              </div>
          </RenderIf>
        </div>
      </div>{" "}
    </div>
  );
};

export default Header;
