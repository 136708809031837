import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Button from "../Button/Button";
import { ReactComponent as ViewIcon } from "./../../assets/svgs/password.svg";
import { ReactComponent as HideIcon } from "./../../assets/svgs/hide-icon.svg";
import RenderIf from "../RenderIf/RenderIf";
import { signupAgency } from "../../redux/agencies/actionCreator";
import { toast } from "react-toastify";

const SignupForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [email, setEmail] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loggedInUser, loading } = useSelector(
    (state) => state.Agencies
  );

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const validateAndSignup = (event) => {
    event.preventDefault();
    if (!termsAgreed) {
      toast.error("You must agree to the terms and conditions.")
      return;
    }
    if (password !== confirmPassword) {
        toast.error("Passwords do not match.")
      return;
    }
    if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        toast.error("Password must be at least 8 characters long, include a number, an uppercase and a lowercase letter.")
      return;
    }
    dispatch(signupAgency({ email, password, streetAddress,city,state,zipCode, agencyName }));
  };

  useEffect(() => {
    if (!loading && loggedInUser && Object.keys(loggedInUser).length > 0) {
        toast.success("New user created successfully!");      
        navigate("/"); // Redirect if the user is logged in
    }
    if (error) toast.error(error);
  }, [loggedInUser, error, loading]);

  return (
    <div className="w-full md:w-1/2 bg-white pt-[43px] px-[35px] md:px-[70px] lg:px-[139px] flex flex-col mb-[32px]">
      <div className="font-semibold text-2xl text-[#333B4E]">
        Register for Scepter
      </div>
      <div className="font-normal text-base mt-[16px] text-[#5A5F7D]">
        Your name, email, and address should match with the details of GHL. If
        these details are not the same then your account will not be created.
      </div>
      <form onSubmit={validateAndSignup}>
        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          Agency Name
        </div>
        <input
          type="text"
          placeholder="Enter Agency Name"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          required
          value={agencyName}
          onChange={(e) => setAgencyName(e.target.value)}
        />

        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          Email
        </div>
        <input
          type="text"
          placeholder="Enter Email"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="font-normal text-sm mt-[24px] text-[#5A5F7D]">
          Password
        </div>
        <div className="relative flex items-center mt-[4px]">
          <input
            type={isPasswordVisible ? "text" : "password"}
            placeholder="Enter password"
            className="border-[1px] border-[#E3E6EF] h-[36px] pl-[12px] pr-[36px] pt-[8px] pb-[7px] text-sm rounded w-full"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <RenderIf
            isTrue={isPasswordVisible}
            fallback={
              <ViewIcon
                className="absolute right-[12px] h-5 w-5 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            }
          >
            <HideIcon
              className="absolute right-[12px] h-5 w-5 text-gray-400 cursor-pointer"
              onClick={togglePasswordVisibility}
            />
          </RenderIf>
        </div>

        <div className="font-normal text-sm mt-[24px] text-[#5A5F7D]">
          Confirm Password
        </div>
        <div className="relative flex items-center mt-[4px]">
          <input
            type={isConfirmPasswordVisible ? "text" : "password"}
            placeholder="Confirm password"
            className="border-[1px] border-[#E3E6EF] h-[36px] pl-[12px] pr-[36px] pt-[8px] pb-[7px] text-sm rounded w-full"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <RenderIf
            isTrue={isConfirmPasswordVisible}
            fallback={
              <ViewIcon
                className="absolute right-[12px] h-5 w-5 text-gray-400 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              />
            }
          >
            <HideIcon
              className="absolute right-[12px] h-5 w-5 text-gray-400 cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            />
          </RenderIf>
        </div>

        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          Street Address
        </div>
        <input
          type="text"
          placeholder="Enter Street Address"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          required
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
        />

        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          City
        </div>
        <input
          type="text"
          placeholder="Enter City Name"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          required
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />

        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          State
        </div>
        <input
          type="text"
          placeholder="Enter State Name"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          required
          value={state}
          onChange={(e) => setState(e.target.value)}
        />

        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          Zip Code
        </div>
        <input
          type="text"
          placeholder="Enter Zip Code"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          required
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />

        <div className="text-[#9299B8] text-sm font-normal flex items-center mt-[12px] mb-[32px]">
          <input
            type="checkbox"
            required
            checked={termsAgreed}
            onChange={() => setTermsAgreed(!termsAgreed)}
          />
          <span className="ml-[8px]">
            Agree with{" "}
            <span className="font-semibold text-[#556EE6]">
              Terms of Condition
            </span>{" "}
            and{" "}
            <span className="font-semibold text-sm text-[#556EE6]">
              Privacy Policy
            </span>
          </span>
        </div>

        <Button
          backgroundColor="#556EE6"
          textColor="white"
          textContent="Create Account"
          hoverTextColor="#556EE6"
          widthStatus={true}
        />

        <div className="font-normal text-sm mt-[24px] text-[#5A5F7D] text-center">
          Already have an account?{" "}
          <Link to="/login">
            <span className="font-semibold text-sm text-[#556EE6]">
              Sign In
            </span>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
