/* eslint-disable */
import React, { useState, useRef, useEffect, useCallback } from "react";
import RenderIf from "../RenderIf/RenderIf";
import { updateCompany } from "../../redux/companies/actionCreator";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const PaymentStatus = ({ value, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const dropdownRef = useRef(null);

  const toggling = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  const onOptionClicked = useCallback(
    (option) => {
      return () => {
        setSelectedOption(option);
        dispatch(updateCompany(id, option, "payment_status"));
        toast.success("Payment status updated successfully!");
        setIsOpen(false);
      };
    },
    [id, dispatch]
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = ["Paid", "Unpaid", "Cancelled"];

  const textColorClass =
    selectedOption === "Paid"
      ? "text-[#34C38F] dark:bg-[#2B3F4A] bg-[#ebf9f4] rounded-full"
      : selectedOption == "Cancelled" ? "text-[#F46A6A] dark:bg-[#3E3646] bg-[#fef0f0] rounded-full" : "text-[#ff9801] bg-[#FFF2E9] dark:bg-[#3f3a3c] rounded-full";

  return (
    <div
      className="select-none font-medium relative text-base whitespace-pre-wrap cursor-pointer px-2"
      ref={dropdownRef}
    >
      <div className={textColorClass}>
        <span
          className="flex text-xs font-medium px-2 py-1 whitespace-nowrap justify-around"
          onClick={toggling}
        >
          {selectedOption}
          <span className="ml-1 rotate-90 text-xs">&#x276F;</span>
        </span>
      </div>
      <RenderIf isTrue={isOpen}>
        <ul className="p-0 w-full absolute shadow-md font-normal text-xs mt-1 list-none rounded-md border-solid dark:border-[#30384A] text-[#333B4E] bg-white dark:text-[#C3CBE4] dark:bg-[#2A3042] z-50 max-w-full max-h-[270px] overflow-auto border-[1px] border-[#CED4DA]">
          {options.map((option) => (
            <li
              className="cursor-pointer text-start p-2 font-normal text-xs hover:text-[#556EE6]"
              onClick={onOptionClicked(option)}
              key={option}
            >
              {option}
            </li>
          ))}
        </ul>
      </RenderIf>
    </div>
  );
};

export default PaymentStatus;
