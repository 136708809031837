import actions from "./actions";
import api from "../../utils/apiData";
import { isSuccess } from "../../utils/commonMethods";
import { jwtDecode } from "jwt-decode";

const {
  fetchAgenciesBegin,
  fetchAgenciesSuccess,
  loginAgencyBegin,
  loginAgencySuccess,
  fetchLoggedInUserBegin,
  fetchLoggedInUserSuccess,
  signupAgencyBegin,
  signupAgencySuccess,
  apiError,
  clearMessageError,
} = actions;

export const fetchAgencies = () => async (dispatch) => {
  try {
    dispatch(fetchAgenciesBegin());
    const response = await api.companies.getAllAgencies();
    if (isSuccess(response))
      dispatch(fetchAgenciesSuccess(response.data.names));
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const loginAgency =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(loginAgencyBegin());
      const response = await api.companies.loginAgencyUser(email, password);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        dispatch(loginAgencySuccess(response.data.user));
      } else {
        dispatch(apiError(response.data.message)); // Dispatch the error message
      }
    } catch (error) {
      console.error("Error during API request:", error);
      dispatch(apiError(error.message));
    }
  };

export const signupAgency =
  ({ agencyName,email, password, streetAddress,city,state,zipCode, }) =>
  async (dispatch) => {
    try {
      dispatch(signupAgencyBegin());
      const response = await api.companies.signupAgencyUser(agencyName, email, password,streetAddress,city,state,zipCode);
      if (response.data.success) {
        const token = response.data.token
        localStorage.setItem("token", token);        
        const user = jwtDecode(token)
        dispatch(signupAgencySuccess(user));
      } else {
        dispatch(apiError(response.data.message)); // Dispatch the error message
      }
    } catch (error) {
      console.error("Error during API request:", error);
      dispatch(apiError(error.message));
    }
  };

export const fetchLoggedInUser = (user) => async (dispatch) => {
  dispatch(fetchLoggedInUserBegin());
  dispatch(fetchLoggedInUserSuccess(user));
};

export const clearMessage = () => async (dispatch) => {
  dispatch(clearMessageError());
};
