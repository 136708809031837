const actions = {
  TOGGLE_THEME: "TOGGLE_THEME",
  SET_DARK_THEME: "SET_DARK_THEME",
  SET_LIGHT_THEME: "SET_LIGHT_THEME",

  toggleTheme: () => ({
    type: actions.TOGGLE_THEME,
  }),

  setDarkTheme: () => ({
    type: actions.SET_DARK_THEME,
  }),

  setLightTheme: () => ({
    type: actions.SET_LIGHT_THEME,
  }),
};

export default actions;
