import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const NoCompanyFound = ({ title }) => {
  return (
    <div
      className="flex w-full flex-col items-center justify-center space-y-8"
      style={{ minHeight: "calc(100vh - 8.925rem)" }}
    >
      <ExclamationCircleIcon className="h-32 w-32 text-red-500" />
      <h1 className="text-4xl font-bold dark:text-white text-black">{title}</h1>
    </div>
  );
};

export default NoCompanyFound;
