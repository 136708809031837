import actions from "./actions";

const { TOGGLE_THEME, SET_DARK_THEME, SET_LIGHT_THEME } = actions;

const initState = {
  darkMode: false,
};

const Themes = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case SET_DARK_THEME:
      return {
        ...state,
        darkMode: true,
      };
    case SET_LIGHT_THEME:
      return {
        ...state,
        darkMode: false,
      };
    default:
      return state;
  }
};

export default Themes;
