import actions from "./actions";

const { toggleTheme, setDarkTheme, setLightTheme } = actions;

export const toggleThemeAction = (darkMode) => async (dispatch) => {
  const isDarkMode = darkMode;

  if (isDarkMode) {
    document.documentElement.classList.remove("dark");
    localStorage.setItem("theme", "light");
    dispatch(setLightTheme());
  } else {
    document.documentElement.classList.add("dark");
    localStorage.setItem("theme", "dark");
    dispatch(setDarkTheme());
  }
};

// To apply saved theme on load
export const applySavedTheme = () => async (dispatch) => {
  const savedTheme = localStorage.getItem("theme");
  if (savedTheme === "dark") {
    document.documentElement.classList.add("dark");
    dispatch(setDarkTheme());
  } else {
    document.documentElement.classList.remove("dark");
    dispatch(setLightTheme());
  }
};
