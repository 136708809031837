import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSubAccount,
  fetchAgencyEmail,
} from "../../redux/companies/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";

const DashboardLinkPage = () => {
  const { location_id } = useParams();
  const dispatch = useDispatch();
  const { agencyEmail, currentCompany, success } = useSelector(
    (state) => state.Companies
  );
  const [content, setContent] = useState("");

  useEffect(() => {
    if (location_id) {
      dispatch(fetchSubAccount(location_id));
      dispatch(fetchAgencyEmail(location_id));
    }
  }, [dispatch, location_id]);

  useEffect(() => {
    if (currentCompany && success) {
      const agencyDashboardLink = currentCompany?.agency_dashboard;
      if (agencyDashboardLink) {
        if (currentCompany.payment_status === "Paid") {
          window.location.href = agencyDashboardLink.startsWith("http")
            ? agencyDashboardLink
            : `https://${agencyDashboardLink}`;
        } else {
          const message =
            currentCompany.payment_status === "Unpaid"
              ? "You payment is still unpaid! Please contact "
              : "Your SEO services have been paused. Please contact ";
          setContent(`${message}${agencyEmail?.email} to learn more.`);
        }
      } else {
        const message =
          currentCompany.payment_status === "Paid"
            ? "Your Dashboard is being generated and will be available shortly! Please contact "
            : currentCompany.payment_status === "Unpaid"
            ? "We are now offering SEO services! Please contact "
            : "Your SEO services have been paused. Please contact ";
        setContent(`${message}${agencyEmail?.email} to learn more.`);
      }
    }
  }, [currentCompany, agencyEmail, success]);

  return (
    <div className="bg-white w-full h-screen flex flex-col items-center justify-center">
      {content ? (
        <div className="flex flex-col items-center">
          <span>
            <img
              src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
              alt="Logo"
              className="h-12"
            />
          </span>
          <div className="font-semibold text-2xl mt-10 text-center">
            {content}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardLinkPage;
